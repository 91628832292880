
















































































































































import BaseWizardStepProps from '@/components/Wizard/mixins/BaseWizardStepProps'
import WizardNavButtons from '@/components/Wizard/components/WizardNavButtons.vue'
import WizardStepHeader from '@/components/Wizard/components/WizardStepHeader.vue'
import { InputSetups } from '@/mixins/input-setups'
import resolveCondition from '@/components/Wizard/logic/resolveConditions'
import {
  BaseActiveFields,
  Button, IndexedNavigationButtons,
  LayoutFields, NavigationButtons, SelectAction,
  WizardAction,
  WizardCondition
} from '@/components/Wizard/wizard.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep, get, set } from 'lodash'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    WizardStepHeader,
    WizardNavButtons,
    ValidationObserver
  }
})
export default class BaseLayoutView extends Mixins<BaseWizardStepProps, UseFields, InputSetups>(BaseWizardStepProps, UseFields, InputSetups) {
  @Prop({ type: Object, required: true }) setup!: { type: 'BaseLayout', fields: Array<LayoutFields> }

  radioVariant = {} as Record<string, string | null>

  get fields() {
    this.setup.fields.forEach((f) => {
      if (f.type === 'CommonRadio') {
        this.$set(this.radioVariant, f.path.key, null)
      }
    })

    return this.setup.fields
  }

  get navigationButtons(): IndexedNavigationButtons {
    return cloneDeep(this.pageModel.navigation)
  }

  get hasEmptyValues(): boolean {
    return !Object.values(this.radioVariant).every(v => v !== null)
  }

  handleButtonClick(buttonStruct: Button | { guid:string } & NavigationButtons): void {
    new Promise<void>(resolve => {

      if (Object.values(this.radioVariant).every((value) => value)) {
        const actions: Array<WizardAction> = []

        Object.values(this.radioVariant).forEach((value: string | null) => {
          if (value) {
            actions.push(this.actions[value])
          }
        })

        actions.forEach(this.mergeActionToModel)

        resolve()
      }

      if (buttonStruct.type === 'Button' && buttonStruct.action) {
        const actionToApply = this.actions[buttonStruct.action]

        if (actionToApply.type === 'Merge') {
          this.mergeActionToModel(actionToApply)

          resolve()
        }
      }

      if (buttonStruct.type === 'Complete') {
        this.cacheStep(this.currentStepGuid, this.model)
        this.completeSetup()

        resolve()
      }

      this.cacheStep(this.currentStepGuid, this.model)

      resolve()
    })
      .then(() => {
        if (buttonStruct.goto) {
          const guidToGo = buttonStruct.goto

          if (buttonStruct.type === 'Back') {
            this.changeStep(guidToGo)
          } else {
            this.changeStepWithModel(guidToGo, this.model)
          }
        }
      })
  }

  isModelAvailable(field: LayoutFields): boolean {
    if (field.type !== 'Button' && field.type !== 'Back' && field.type !== 'Next' && field.type !== 'Complete') {
      return this.computedModel(field.path) !== undefined && this.computedModel(field.path)[field.path.key] !== undefined
    }

    return false
  }

  mergeActionToModel(actionToApply: WizardAction): void {
    const keys = Object.keys(actionToApply.actionConfig)

    keys.forEach((key: string) => {
      this.model = set(this.model, key, actionToApply.actionConfig[key])
    })

    this.cacheStep(this.currentStepGuid, this.model)
  }

  computedModel(path: BaseActiveFields['path']): any {
    if (path.model) {
      return get(this.model, path.model)
    } else {
      return this.model
    }
  }

  radioOptions(value: Array<SelectAction>): Array<SelectOption> {
    return value.map(option => {
      return {
        label: this.$t(option.label).toString(),
        value: option.action
      }
    })
  }

  selectOptions(options: Array<SelectOption>): Array<SelectOption> {
    return options.map((option: SelectOption) => {
      return {
        label: this.$t(option.label).toString(),
        value: option.value
      }
    })
  }

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any) => {
      this.$set(args.model, args.key, value)
    }
    return args
  }

  activated() {
    this.model = cloneDeep(this.chatConfig)

    if (this.pageModel.condition) {
      const conditionToResolve: Array<Array<WizardCondition>> = this.conditions[this.pageModel.condition]
      const conditionResult = resolveCondition(conditionToResolve, this.model)

      if (!conditionResult) {
        const nextButton = this.pageModel.navigation.find(b => b.type === 'Next')

        if (nextButton && nextButton.goto) {
          this.cacheStep(this.currentStepGuid, this.model)
          this.changeStepWithModel(nextButton.goto, this.model)
        }
      }
    }
  }

  deactivated() {
    this.model = {}
  }
}
