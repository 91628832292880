











import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class WizardStepHeader extends Vue {
  @Prop({ type: String, required: false }) title!: string

  @Prop({ type: String, required: false, default: '' }) description!: string
}
