import BaseLayoutView from "@/components/Wizard/steps/BaseLayout/BaseLayoutView.vue";

import Vue from "vue";

export class BaseLayout {
  type = 'BaseLayout'
  fields: Array<any> = []

  constructor(layoutData: any) {
    this.type = layoutData.type
    this.fields = layoutData.fields
  }

  getWizardLayoutStruct(): {view: any, props: any} {
    return {
      view: Vue.extend(BaseLayoutView),
      props: {
        setup: {
          type: this.type,
          fields: this.fields
        }
      }
    }
  }

}
