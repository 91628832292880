

























import prepareConfigViews from '@/components/Wizard/logic/prepareConfigViews'
import { ConfigViews, WizardStructure } from '@/components/Wizard/wizard.types'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { cloneDeep, has } from 'lodash'

@Component
export default class Wizard extends Vue {
  @Prop({ type: Object, required: true }) wizardConfig!: WizardStructure

  @Prop({ type: Object }) chatConfig !: any

  wizardStepViews: ConfigViews = {} as ConfigViews

  currentStepGuid = ''

  stepState: Record<string, any> = {}

  @Emit('completeSetup')
  completeSetup(stepGuid: string) {
    return this.stepState[stepGuid]
  }

  cacheStep(value: { stepGuid: string, model: any }): void {
    this.$set(this.stepState, value.stepGuid, value.model)
  }

  onStepChange(gotoGuid: string): void {
    this.currentStepGuid = gotoGuid
  }

  onStepChangeModel(value: { guid: string, model: any }): void {
    this.currentStepGuid = value.guid
    this.$set(this.stepState, value.guid, cloneDeep(value.model))
  }

  get wizardViews(): any {
    return Object.entries(this.wizardStepViews)
  }

  async prepareConfig(): Promise<ConfigViews> {
    if (!has(this.wizardConfig, 'nodes')) {
      throw 'Missing required root "nodes" property'
    }

    return prepareConfigViews(this.wizardConfig)
  }

  mounted(): void {
    this.prepareConfig().then((data) => {
      this.wizardStepViews = data

      Object.keys(this.wizardStepViews).forEach((stepKey, index) => {
        if (index === 0) {
          this.$set(this.stepState, stepKey, cloneDeep(this.chatConfig))

          this.currentStepGuid = stepKey
        } else {
          this.$set(this.stepState, stepKey, {})
        }
      })
    })
  }
}
