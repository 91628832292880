

























import {
  IndexedNavigationButtons,
  NavigationButtons,
} from '@/components/Wizard/wizard.types'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component
export default class WizardNavButtons extends Vue {
  @Prop() navButtons!: IndexedNavigationButtons

  @Prop({ type: Boolean }) disabled!: boolean

  @Emit('onNavButtonClick')
  onButtonClick(clickedButton: { guid:string } & NavigationButtons) {
    return clickedButton
  }

  getNavBtnTitle(buttonType: NavigationButtons['type']): string {
    if (buttonType === 'Next') return this.$t('wizard_next_button').toString()
    if (buttonType === 'Back') return this.$t('wizard_prev_button').toString()
    if (buttonType === 'Complete') return this.$t('wizard_complete_button').toString()

    return ''
  }

  getButtonColorByType(buttonType: NavigationButtons['type']): string {
    if (buttonType === 'Complete') return 'success'

    return 'primary'
  }

  getIconByType(buttonType: NavigationButtons['type']): string {
    if (buttonType === 'Next') return 'arrow-right'
    if (buttonType === 'Back') return 'arrow-left'
    if (buttonType === 'Complete') return 'check'

    return ''
  }
}
