















































import Wizard from '@/components/Wizard/Wizard.vue'
// const config = require('../components/Wizard/wizardConfig.json')
const stop_words_config = require('../components/Wizard/stop_words_filter_config.json')

import { InputSetups } from '@/mixins/input-setups'

import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'
import { Mixins } from 'vue-property-decorator'
import { errorNotification } from '@/includes/NotificationService'

@Component({
  components: {
    Wizard,
    Overlay
  },
})
export default class WizardPage extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  isWizardOpen = false

  config = stop_words_config

  completeSetup(newConfig: any): void {
    this.$store.commit('DEEP_MERGE_OBJECTS', {
      target: this.$store.state.chatState.chat.config,
      src: newConfig,
    })

    this.$store.dispatch('saveChatConfig').then(() => {
      this.isWizardOpen = false
    })
  }

  get chatConfig(): any {
    return cloneDeep(this.$store.state.chatState.chat.config)
  }

  get wizardConfig(): any {
    try {
      return JSON.parse(this.config)
    } catch (e) {
      // errorNotification(e)
      return {}
    }
    // if (JSON.parse(this.config))
    //   return JSON.parse(this.config)
    // return {}
  }
}
