var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-layout flex flex-col w-full h-full"},[_c('div',{staticClass:"base-layout__body flex flex-col justify-center items-center w-full h-full"},[_c('wizard-step-header',{staticClass:"text-center",attrs:{"title":_vm.pageModel.titleTranslationKey,"description":_vm.pageModel.descriptionTranslationKey}}),(_vm.setup && _vm.setup.fields)?_c('div',{staticClass:"base-layout__layout flex flex-col items-center md:w-2/3"},_vm._l((_vm.fields),function(field,index){return _c('div',{key:index,staticClass:"w-full"},[(['ActiveFieldChips'].includes(field.type) && _vm.isModelAvailable(field))?_c('chips-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
            }
          }}}):_vm._e(),(['ActiveFieldSelect'].includes(field.type) && _vm.isModelAvailable(field))?_c('select-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
              'options': _vm.selectOptions(field.options),
              'clearable': false,
            }
          }}}):_vm._e(),(['ActiveFieldCheckbox'].includes(field.type) && _vm.isModelAvailable(field))?_c('checkbox-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
            }
          }}}):_vm._e(),(['ActiveFieldNumber'].includes(field.type) && _vm.isModelAvailable(field))?_c('number-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
            }
          }}}):_vm._e(),(['ActiveFieldSwitch'].includes(field.type) && _vm.isModelAvailable(field))?_c('switch-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
            }
          }}}):_vm._e(),(['ActiveFieldBlackWhitelist'].includes(field.type) && _vm.isModelAvailable(field))?_c('black-white-list-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
            }
          }}}):_vm._e(),(['ActiveFieldTimeUnit'].includes(field.type) && _vm.isModelAvailable(field))?_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.computedModel(field.path),
              'key': field.path.key,
              'validation': 'required',
            }
          }}}):_vm._e(),(field.type === 'CommonRadio')?_c('radio-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.radioVariant,
              'key': field.path.key,
              'options': _vm.radioOptions(field.options),
              'validation': 'required',
              'prefix': field.prefix ? ((field.prefix) + "_") : ''
            }
          }}}):_vm._e(),_c('div',{staticClass:"w-full flex justify-center mt-2"},[(field.type === 'Button')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleButtonClick(field)}}},[_vm._v(" "+_vm._s(_vm.$t(field.fieldTranslationKey))+" ")]):_vm._e()],1)],1)}),0):_vm._e()],1),_c('div',{staticClass:"base-layout__footer w-full"},[_c('div',{staticClass:"base-layout__navigation"},[_c('wizard-nav-buttons',{staticClass:"flex justify-end",attrs:{"nav-buttons":_vm.navigationButtons,"disabled":_vm.hasEmptyValues},on:{"onNavButtonClick":_vm.handleButtonClick}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }