import { WizardCondition } from "@/components/Wizard/wizard.types";
import { OperandType } from "@/components/Wizard/logic/types";

import { isEmpty, get, isObject } from 'lodash'

export default function resolveCondition(conditions: Array<Array<WizardCondition>>, model: any): boolean {
  const result: Array<boolean> = []

  conditions.forEach(conditionGroup => {
    const groupResult: Array<boolean> = []

    conditionGroup.forEach((condition) => {
      if (condition.type === OperandType.EMPTY) {
        groupResult.push(doEmptyLogic(condition.params, model))
      }
      if (condition.type === OperandType.NOT_EMPTY) {
        groupResult.push(doNotEmptyLogic(condition.params, model))
      }
    })

    result.push(groupResult.some(value => value))
  })

  return result.every(value => value)
}

function doEmptyLogic(value: string | Array<string> | object, model: any): boolean {
  const results: Array<boolean> = []

  if (typeof value === 'string') {
    if (!isObject(get(model, value))) {
      results.push(!get(model, value))
    } else {
      results.push(!isEmpty(get(model, value)))
    }
  }

  if (Array.isArray(value)) {
    value.forEach(path => {
      if (!isObject(get(model, path))) {
        results.push(!get(model, path))
      } else {
        results.push(!isEmpty(get(model, path)))
      }
    })
  }

  return results.every(result => result)
}

function doNotEmptyLogic(value: string | Array<string> | object, model: any): boolean {
  const results: Array<boolean> = []

  if (typeof value === 'string') {
    if (!isObject(get(model, value))) {
      results.push(Boolean(get(model, value)));
    } else {
      results.push(isEmpty(get(model, value)));
    }
  }

  if (Array.isArray(value)) {
    value.forEach(path => {
      if (!isObject(get(model, path))) {
        results.push(get(model, path))
      } else {
        results.push(isEmpty(get(model, path)))
      }
    })
  }

  return results.every(result => result)
}
