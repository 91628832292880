import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import {
  BaseWizardPage,
  IndexedNavigationButtons,
  WizardActions,
  WizardConditions
} from "@/components/Wizard/wizard.types";

@Component
export default class BaseWizardStepProps extends Vue {
  @Prop({ type: Object }) chatConfig!: any

  @Prop({ type: Object }) pageModel!: Omit<BaseWizardPage, 'navigation'> & { navigation: IndexedNavigationButtons }

  @Prop({ type: Object }) actions !: WizardActions

  @Prop({ type: Object }) conditions !: WizardConditions

  @Prop({ type: String }) currentStepGuid !: string

  model: any = {}

  @Emit('onComplete')
  completeSetup(): string {
    return this.currentStepGuid
  }

  @Emit('cacheStep')
  cacheStep(stepGuid: string, model: any): { stepGuid: string, model: any } {
    return { stepGuid, model }
  }

  @Emit('onStepChange')
  changeStep(gotoGuid: string): string {
    return gotoGuid
  }

  @Emit('onStepChangeModel')
  changeStepWithModel(guid: string, model: any): { guid: string, model: any } {
    return { guid, model }
  }
}
