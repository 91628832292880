import { BaseLayout } from "@/components/Wizard/steps/BaseLayout/BaseLayout";

import { Guid } from "guid-typescript";
import {
  ConfigViews,
  IndexedNavigationButtons,
  PageNavigationButtons,
  RequestWizardPage,
  WizardActions,
  WizardConditions,
  WizardLayout,
  WizardStructure
} from "@/components/Wizard/wizard.types";

export default function prepareConfigViews(config: WizardStructure): ConfigViews {
  const viewSteps = {} as ConfigViews

  for (let [ guid, value ] of Object.entries(config.nodes)) {
    viewSteps[guid] = {
      ...getStepViewByLayoutType(value.page.layout),
      page: {
        titleTranslationKey: getPageTitle(guid, value.page),
        descriptionTranslationKey: getPageDescription(value.page),
        condition: value.page.condition,
        ...getNavigationButtons(value.page.navigation),
      },
      ...getStepActions(value.actions),
      ...getStepConditions(value.conditions),
    }
  }

  return viewSteps
}

function getNavigationButtons(configPageNavigation: PageNavigationButtons): { navigation: IndexedNavigationButtons } {
  const navigationButton: IndexedNavigationButtons = configPageNavigation
      .map(b => ({ guid: Guid.create().toString(), ...b }))
      .filter(b => b.visible)

  return { navigation: navigationButton }
}

function getStepActions(actions: WizardActions): { actions: WizardActions } {
  return {
    actions: { ...actions }
  }
}

function getStepConditions(conditions: WizardConditions): { conditions: WizardConditions } {
  return {
    conditions: { ...conditions }
  }
}

function getPageTitle(stepGuid: string, page: RequestWizardPage): string {
  return page.titleTranslationKey ? page.titleTranslationKey : stepGuid
}

function getPageDescription(page: RequestWizardPage): string {
  return page.descriptionTranslationKey ? page.descriptionTranslationKey : ''
}

function getStepViewByLayoutType(layoutConfig: WizardLayout): { view: any, props: any } | undefined {
  if (layoutConfig.type === 'BaseLayout') {
    return new BaseLayout(layoutConfig).getWizardLayoutStruct()
  }
}
